const VERSION_INFO_KEY = 'version-info';
const POLL_INTERVAL_MS = 60 * 1000;

const getCurrentVersionInfo = async () => {
	return await (await fetch('/version-info.md', { cache: 'no-store' })).text();
};

const setInitialVersionInfo = async () => {
	const versionInfo = await getCurrentVersionInfo();

	if (typeof window.sessionStorage !== 'undefined') {
		window.sessionStorage.setItem(VERSION_INFO_KEY, versionInfo);
	}
};

const isUpdateAvailable = async () => {
	const versionInfo = await getCurrentVersionInfo();
	if (typeof window.sessionStorage !== 'undefined') {
		if (window.sessionStorage.getItem(VERSION_INFO_KEY) !== versionInfo) {
			return true;
		}
	}

	return false;
};

const showUpdateAvailableToUser = () => {
	const div = document.createElement('div');
	div.classList.add('app-refresh');
	div.innerHTML = `<button><strong>🔄 An updated version of Future Plans is now available!</strong> Click to update.</button>`;

	div.querySelector('button')
		.addEventListener('click', () => {
			window.location.reload();
		});

	let target = document.querySelector('.inside-app-container');
	if (target === null) {
		target = document.body;
	}

	target.insertAdjacentElement('afterbegin', div);
};

const pollForApplicationUpdates = () => {
	let timeoutId = null;

	const timeoutRoutine = () => {
		if (timeoutId !== null) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(async () => {
			let updateAvailable = false;

			try {
				updateAvailable = await isUpdateAvailable();

				if (updateAvailable) {
					showUpdateAvailableToUser();
				}
			}
			catch (ex) {
				console.error(ex);
			}
			finally {
				if (!updateAvailable) {
					timeoutRoutine();
				}
			}
		}, POLL_INTERVAL_MS);
	};

	timeoutRoutine();
};

(async () => {
	await setInitialVersionInfo();
	pollForApplicationUpdates();
})();