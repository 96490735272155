import $ from 'jquery';

// ------------------------------------------------------------------------------------
// Resizes the application heading title based on how many characters are in the title
// ------------------------------------------------------------------------------------
$(document).ready(function () {
	if (window.innerWidth > 768) {
		var $elem = $('.inside-app-heading-title');
		var length = $elem.text().length;

		var size = null;

		if (length < 20) {
			size = '3.5vw';
		}
		else if (length < 40) {
			size = '3vw';
		}
		else if (length < 60) {
			size = '2vw';
		}
		else {
			size = '1.5vw';
		}

		$elem.css('font-size', size);
	}
});