import $ from 'jquery';
import '../../../vendor/clamp-js/clamp.js';
import '../modals';

$(document).ready(function () {
	$('#NotificationAnchor').click(function (e) {
		if ($('.notification-side-modal').css('opacity') == 0) {
			$('.notification-side-modal')
				.css({
					top: $(window).height() - 400,
					left: $(window).width() - 255,
					"pointer-events": "auto"
				})
				.removeClass('animate__fadeOutRight')
				.addClass('animate__fadeInRight');

			e.stopPropagation();
		}
	});

	$('.notification-side-modal').click(function (e) {
		if ($(this).css('opacity') == 1)
			e.stopPropagation();
	});

	$(document).click(function () {
		hide_notifications();
	});

	$('.notification-side-modal span').each(function () { $clamp(this, { clamp: 2 }); });

	$('.notification-item').click(function () {
		const $notification = $(this);

		// Mark as read and update styling
		mark_notification_read(this, true);

		const contentType = $notification.data('content-type');
		if (contentType == 'Link') {
			window.location = $notification.data('content');
		}
		else if (contentType == 'Html') {
			show_modal_notification($notification.data('header-text'), $notification.data('content'));
		}

		hide_notifications();
	});

	var loadingMoreNotifications = false;
	$('.load-more-notifications').click(function () {
		if (loadingMoreNotifications) return;

		const $button = $(this);

		// Prevent multiple clicks until the AJAX call is completed
		loadingMoreNotifications = true;
		$button.text('Loading...');

		const numNotificationsLoaded = parseInt($button.data('num-loaded'), 10);
		const numNotificationsToLoad = parseInt($button.data('num-to-load'), 10);
		const $previous = $($button.data('previous-selector'));
		const $template = $($button.data('template-selector'));

		$.post('/Common/GetNotifications', { skip: numNotificationsLoaded, take: numNotificationsToLoad }, function (result) {

			/*
			// TEST DATA
			result.Success = true;
			result.Notifications = [
				{ ID: 2, SentOn: new Date(2012, 11, 12), ReadOn: null, HeaderText: 'Testing 3', BodyType: 'Html', BodyContent: 'Test body' },
				{ ID: 1, SentOn: new Date(2011, 10, 11), ReadOn: null, HeaderText: 'Testing 2', BodyType: 'Html', BodyContent: 'Test body' },
				{ ID: 0, SentOn: new Date(2010, 9, 10), ReadOn: null, HeaderText: 'Testing 1', BodyType: 'Html', BodyContent: 'Test body' },
			];
			*/

			if (result.Success && result.Notifications && Array.isArray(result.Notifications)) {
				// Insert new divs for each notification
				let $previousNotification = $previous;

				result.Notifications.forEach(function (notification) {
					// Update envelope, title, and content
					var $newNotification = $template.clone(true);

					// Transform from template to notification:
					$newNotification
						.removeClass('notification__template')
						.show();

					if ($newNotification.data('display')) {
						$newNotification.css('display', $newNotification.data('display'));
					}

					$newNotification.data('notification-id', notification.ID);
					$newNotification.data('is-read', notification.ReadOn ? 'true' : 'false');
					$newNotification.data('header-text', notification.HeaderText);
					$newNotification.data('content-type', notification.BodyType);
					$newNotification.data('content', notification.BodyContent);

					$newNotification.removeClass('read unread').addClass(notification.ReadOn ? 'read' : 'unread');
					$newNotification.find('.notification-header').css('font-weight', notification.ReadOn ? 'normal' : 'bold')
					$newNotification.find('.notification__header-text').text(notification.HeaderText);
					$newNotification.find('.notification__icon').removeClass('fa-envelope fa-envelope-open-text').addClass(notification.ReadOn ? 'fa-envelope-open-text' : 'fa-envelope');

					const sentOn = fp_date_from_utc(notification.SentOn);
					$newNotification.find('.notification-sent-date, .notification__sent date').text(fp_formatted_date(sentOn));
					$newNotification.find('.notification__sent time').text(fp_formatted_time(sentOn));

					$newNotification.insertAfter($previousNotification);

					$previousNotification = $newNotification;
				});

				// Update loaded data attr
				$button.data('num-loaded', numNotificationsLoaded + result.Notifications.length);

				//if (result.AtEnd) $button.parent().off('click'); // No longer detect clicks

				$('.notification-mark-all-read').attr('disabled', false);
			}
			loadingMoreNotifications = false;

			if (result.AtEnd) {
				$button.text('All Caught Up!');
				$button.attr('disabled', 'disabled');
			}
			else {
				$button.html('<i class="fa-solid fa-refresh"></i> Load More');
			}
		})
			.fail(function () {
				loadingMoreNotifications = false;
				$button.html('<i class="fa-solid fa-refresh"></i> Load More');
			});
	});

	$('.notification-mark-all-read').click(function () {
		// Do nothing if there are no unread notifications or we're still waiting on the last callback
		var $button = $(this);
		var checks = $button.find('i.fa-check');
		var hourglass = $button.find('i.spin-hourglass');
		if (!$button.hasClass('clickable'))
			return;

		show_modal_confirm('Are you sure you want to mark ALL of your notifications as read?', 'Mark All as Read', function () {
			$button.removeClass('clickable');
			checks.addClass('hidden');
			hourglass.removeClass('hidden');

			$.post('/Common/MarkAllNotificationsRead', function (result) {
				if (result.Success) {
					// Update styling on all notifications without posting anything
					$('.notification-item').each(function () {
						mark_notification_read(this, false);
					});

					// Deactivate button; will be reenabled if Load More loads more
					$button.attr('disabled', 'disabled');
				}
			})
				.always(function () {
					hourglass.addClass('hidden');
					checks.removeClass('hidden');
					$button.addClass('clickable');
				});
		});
	});
});

function hide_notifications() {
	if ($('.notification-side-modal').css('opacity') == 1) {
		$('.notification-side-modal').removeClass('animate__fadeInRight').addClass('animate__fadeOutRight').css('pointer-events', 'none');
	}
}

function mark_notification_read(notificationItemEl, shouldPost) {
	const $notification = $(notificationItemEl);
	if ($notification.data('is-read') == true) return;

	$notification.removeClass('read unread').addClass('read');
	$notification.find('.notification-header').css('font-weight', 'normal');
	$notification.find('.notification__icon').removeClass('fa-envelope fa-envelope-open-text').addClass('fa-envelope-open-text');

	var newUnreadCount = $('#UnreadNotificationsCount').val() - 1;
	$('#UnreadNotificationsCount').val(newUnreadCount);
	$('#NotificationAnchor badge').text(newUnreadCount);

	if (shouldPost)
		$.post('/Common/MarkNotificationRead', { id: $notification.data('notification-id') });

	// If there are no more unread notifications, remove the red counter and stop animating the bell
	if (newUnreadCount == 0) {
		$('#NotificationAnchor badge').remove();
	}

	// Update window title
	var titleUnreadCount = newUnreadCount < 1 ? '' : ('(' + (newUnreadCount > 99 ? '99+' : newUnreadCount) + ') ');
	document.title = titleUnreadCount + $('#TitleWithoutNotifications').val();
}

window.hide_notifications = hide_notifications;