import $ from 'jquery';
import * as toastr from 'toastr';
import { show_modal_form } from '../modals';
import { postJson } from '../util/fp-fetch';

const showBeta = function () {
	$(document).ready(function () {
		const $elem = $('beta');
		const $content =
			$(`<span class="beta">
				<span class="label beta__label" title="This is a beta feature that Future Plans has released for early access and may not be complete or functional for all use cases.  If you run into any issues with this feature, please let us know, and be sure to check back as it is in active development."><i class="fa fa-flask"></i> <span style="padding-left: 2px;">BETA</span></span>
				<button class="beta__bug">
					<span class="fa-stack" title="Did you find a bug?">
						<i class="fa-solid fa-circle fa-stack-2x" style="color: #ffffff;"></i>
						<i class="fa-solid fa-bug fa-stack-1x" style="color: #8300fd;"></i>
					</span>
				</button>
			</span>`);

		$content.find('.beta__bug').on('click', showBugModal);
		$content.insertBefore($elem);

		$elem.remove();
	});
}

const showBugModal = function () {
	show_modal_form(
		'Did you find a bug?',
		`<div class="bug__form">
				<div>Please report the bug that you encountered so we can better improve Future Plans.</div>
				<div class="input-wrapper">
					<label class="input-label">What were you attempting to do?</label>
					<input type="text" class="input-field bug__attempting" placeholder="e.g., I was trying to enter my name in the form.">
				</div>
				<div class="input-wrapper">
					<label class="input-label">What did you expect to happen?</label>
					<input type="text" class="input-field bug__expecting" placeholder="e.g., I expected to be able to type my name.">
				</div>
				<div class="input-wrapper">
					<label class="input-label">What actually happened?</label>
					<input type="text" class="input-field bug__actually" placeholder="e.g., The form would not let me type my name.">
				</div>
				<div class="input-wrapper">
					<label class="input-label">What other information would you like to provide?</label>
					<textarea class="input-field bug__info" style="width: 100%; height: 200px;" placeholder="e.g., I tried copying and pasting my name in the form and that worked, but I was not able to directly type my name."></textarea>
				</div>
				<div class="input-wrapper">
					<label class="input-label">What's the best way for us to contact you if we have any follow up questions?</label>
					<input type="text" class="input-field bug__contact" placeholder="Please provide a phone number or email address">
				</div>
			</div>`,
		'Submit Bug Report',
		'Cancel',
		submitBugModal,
		dismissBugModal);
};

const submitBugModal = async function ($modal) {
	const payload = {
		Url: window.location.href,
		Document: window.document.documentElement.outerHTML,
		Attempted: $modal.find('.bug__attempting').val(),
		Expected: $modal.find('.bug__expecting').val(),
		Actual: $modal.find('.bug__actually').val(),
		Info: $modal.find('.bug__info').val(),
		Contact: $modal.find('.bug__contact').val(),
	};

	try
	{
		await submitBug(payload);
		updateBugModalAfterSubmission($modal);
	}
	catch (ex) {
		toastr.error(ex.message);
	}
};

const submitBug = async function (payload) {
	await postJson('/API/BugReports', payload);
};

const updateBugModalAfterSubmission = function ($modal) {
	$modal.find('.modal-title').html('Thank you for reporting this bug!');

	const $content =
		$(`<div>
				<h4>Your feedback helps us make Future Plans better!</h4>
				<div>Our development team will be reviewing your bug submission and may contact you for additional details.</div>
			</div>`);

	const $actions = $('<button type="button" class="button save-button">Close</button>');
	$actions.on('click', () => {
		$modal.modal('hide');
	});

	$modal.find('.modal-body').html($content);
	$modal.find('.modal-footer').html($actions);
};

const dismissBugModal = async function ($modal) {
	$modal.modal('hide');
};

showBeta();

window.showBeta = showBeta;

export {
	showBeta,
	showBugModal,
	submitBug,
};