/*
 * theme-ui-toggler
 * -----------------------------------------------------------------------------------------------------------------------------------
 * Attaches a theme toggler to the "theme" navigation icon and captures user theme change events.
 * 
 * Prerequisites:
 * - The <body> element of the document must contain a class called "themeable".
 *   For example:  <body class="themeable">...</body>
 * - For controlling the theme, an anchor element with the id ThemeAnchor should exist to attach a click handler to.
 */

import { setTheme, getCurrentTheme, AUTO_MODE, DARK_MODE, LIGHT_MODE } from './theme-handler';

const isThemingEnabled = function () {
	return document.body.classList.contains('themeable');
};

const closeModal = function (modal) {
	document.body.removeChild(modal);
};

const setup = function () {
	document.addEventListener('DOMContentLoaded', function () {
		const anchor = document.getElementById('ThemeAnchor');
		if (anchor === null) {
			//console.warn('Unable to find theme anchor.');
			return;
		}

		anchor.addEventListener('click', async function (event) {
			event.preventDefault();

			const modalId = "theme-ui-toggler";

			let modal = document.getElementById(modalId);
			const isOpen = modal === null ? false : true;

			if (!isOpen) {
				const bodyRect = document.body.getBoundingClientRect();
				const anchorRect = anchor.getBoundingClientRect();

				const modalTop = anchorRect.top - bodyRect.top - 130;
				const modalLeft = anchorRect.left - bodyRect.left - 5;

				const currentTheme = getCurrentTheme();

				document.body.insertAdjacentHTML('beforeend', `
				<div id="${modalId}" style="position: absolute; left: ${modalLeft}px; top: ${modalTop}px;">
					<ul>
						<li><button type="button" data-value="${AUTO_MODE}" class="${currentTheme === AUTO_MODE ? 'selected' : ''}"><i class="fa-fw fa-solid fa-circle-half-stroke"></i> Auto</button></li>
						<li><button type="button" data-value="${LIGHT_MODE}" class="${currentTheme === LIGHT_MODE ? 'selected' : ''}"><i class="fa-fw fa-solid fa-sun"></i> Light</button></li>
						<li><button type="button" data-value="${DARK_MODE}" class="${currentTheme === DARK_MODE ? 'selected' : ''}"><i class="fa-fw fa-solid fa-moon"></i> Dark</button></li>
					</ul>
				</div>
			`);

				modal = document.getElementById(modalId);
				const buttons = modal.getElementsByTagName('button');
				for (let button of buttons) {
					button.addEventListener('click', async function (event) {
						event.preventDefault();

						const newTheme = event.currentTarget.dataset.value;
						await setTheme(newTheme, true);

						closeModal(modal);
					});
				}
			}
			else {
				closeModal(modal);
			}
		});
	});
};

if (isThemingEnabled()) {
	setup();
}
