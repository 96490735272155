/*
 * theme-handler
 * -----------------------------------------------------------------------------------------------------------------------------------
 * Sets the initial theme and handles theme change events.
 * 
 * Prerequisites:
 * - The <body> element of the document must contain a class called "themeable".
 *   For example:  <body class="themeable">...</body>
 * - An element with id "user-theme" (USER_THEME_ELEM_ID) should have a data attribute for "value" (data-value)
 *   set with the user's theme selection, defaulting to "auto".  This element should appear BEFORE this script is loaded in the DOM.
 *   For example:  <div id="user-theme" data-value="@(currentUser.Theme ?? ThemeOptions.Auto)" style="display: none;"></div>
 * - For controlling the theme, an anchor element with the id ThemeAnchor should exist, as well as an <icon> element.
 */

export const AUTO_MODE = 'auto';
export const DARK_MODE = 'dark';
export const LIGHT_MODE = 'light';

const AUTO_ICON_CLASS = 'theme--auto';
const DARK_ICON_CLASS = 'theme--dark';
const LIGHT_ICON_CLASS = 'theme--light';

const DARK_THEME_CLASS = 'dark-theme';

const USER_THEME_ELEM_ID = 'user-theme';

let documentLoaded = false;
let queuedFns = [];
let currentTheme = null;

const isThemingEnabled = function () {
	return document.body.classList.contains('themeable');
};

const runWhenLoaded = function (fn) {
	if (documentLoaded) fn();
	else queuedFns.push(fn);
};

const persistUserThemeSelection = async function (theme) {
	const response = await fetch(`/API/Users/SetUserTheme`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		body: JSON.stringify({
			theme: theme
		}),
	});

	if (!response.ok) throw new Error(response.statusText);
	const result = await response.json();
	if (typeof result.Success === 'undefined') throw new Error('An unexpected error was encountered.');
	if (!result.Success) throw new Error(result.Errors.join("\n"));
};

const getUserTheme = function () {
	const userThemeElem = document.getElementById(USER_THEME_ELEM_ID);
	if (userThemeElem === null) {
		console.warn('Could not find user theme element!');
		return AUTO_MODE;
	}

	return userThemeElem.dataset.value;
};

const setUserTheme = function (theme) {
	const userThemeElem = document.getElementById(USER_THEME_ELEM_ID);
	if (!userThemeElem) {
		console.warn('Could not find user theme element!');
		document.body.insertAdjacentHTML('afterbegin', `<div id="${USER_THEME_ELEM_ID}" data-value="${theme}" style="display: none;"></div>`);
		return;
	}
	userThemeElem.dataset.value = theme;
};

export const getCurrentTheme = function () {
	return currentTheme;
};

export const getSystemTheme = function () {
	if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
		return DARK_MODE;
	}

	return LIGHT_MODE;
};

export const setTheme = async function (theme, manuallySet) {
	if (theme === DARK_MODE) applyDarkMode(true);
	else if (theme === LIGHT_MODE) applyLightMode(true);
	else applyAutoMode();

	if (manuallySet) {
		try {
			await persistUserThemeSelection(theme);
			setUserTheme(theme);
		}
		catch (ex) {
			console.error(ex);
		}
	}

	currentTheme = theme;
};

const getThemeIcon = function () {
	const anchor = window.document.getElementById('ThemeAnchor');
	if (anchor === null) {
		//console.warn('Unable to find theme anchor.');
		return null;
	}

	const icon = anchor.getElementsByTagName('icon')[0];
	if (icon === null) {
		//console.warn('Unable to find theme icon.');
		return null;
	}

	return icon;
};

const clearTheme = function() {
	window.document.body.classList.remove(DARK_THEME_CLASS);
};

const clearIcon = function (icon) {
	icon.classList.remove(AUTO_ICON_CLASS);
	icon.classList.remove(LIGHT_ICON_CLASS);
	icon.classList.remove(DARK_ICON_CLASS);
};

const applyAutoMode = function () {
	const theme = getSystemTheme();

	if (theme === DARK_MODE) applyDarkMode(false);
	else applyLightMode(false);

	runWhenLoaded(function () {
		const icon = getThemeIcon();
		if (icon === null) {
			return;
		}

		clearIcon(icon);
		icon.classList.add(AUTO_ICON_CLASS);
	});
};

const applyDarkMode = function (setIcon) {
	clearTheme();
	window.document.body.classList.add(DARK_THEME_CLASS);

	if (setIcon) {
		runWhenLoaded(function () {
			const icon = getThemeIcon();
			if (icon === null) {
				return;
			}

			clearIcon(icon);
			icon.classList.add(DARK_ICON_CLASS);
		});
	}
};

const applyLightMode = function (setIcon) {
	clearTheme();

	if (setIcon) {
		runWhenLoaded(function () {
			const icon = getThemeIcon();
			if (icon === null) {
				return;
			}

			clearIcon(icon);
			icon.classList.add(LIGHT_ICON_CLASS);
		});
	}
};

const setup = function () {
	const userTheme = getUserTheme();

	(async function () {
		await setTheme(userTheme, false);
	})();

	runWhenLoaded(function () {
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', async (event) => {
			if (userTheme === AUTO_MODE) {
				const newTheme = event.matches ? DARK_MODE : LIGHT_MODE;
				await setTheme(newTheme, false);
			}
		});
	});

	document.addEventListener('DOMContentLoaded', async function () {
		documentLoaded = true;
		queuedFns.forEach(fn => fn());
		queuedFns = [];
	});
} 

if (isThemingEnabled()) {
	setup();
}
