import $ from 'jquery';
import './components/app-notifications';
import './components/navigation';
import './components/forms/form-submit-splash.js';
import './components/util';
import './components/tag-helpers';
import './components/theme';

console.log(`
         ___________      __                         __________.__
         \\_   _____/_ ___/  |_ __ _________   ____   \\______   \\  | _____    ____   ______
          |    __)|  |  \\   __\\  |  \\_  __ \\_/ __ \\   |     ___/  | \\__  \\  /    \\ /  ___/
          |     \\ |  |  /|  | |  |  /|  | \\/\\  ___/   |    |   |  |__/ __ \\|   |  \\\\___ \\
          \\___  / |____/ |__| |____/ |__|    \\___  >  |____|   |____(____  /___|  /____  >
              \\/                                 \\/                      \\/     \\/     \\/


                                     Made with \u2764 in Cleveland, Ohio

                                       Be excellent to each other!

`);

window.credits = function () {
	console.log(`
                            ________________________________________________
                           /                                                \\
                          |    _________________________________________     |
                          |   |                                         |    |
                          |   |  C:\\> credits                           |    |
                          |   |                                         |    |
                          |   |  Development Team                       |    |
                          |   |  ================                       |    |
                          |   |  Shari DeMarco                          |    |
                          |   |  Clifford Oravec                        |    |
                          |   |  Brandon Beck                           |    |
                          |   |  Jason Cook                             |    |
                          |   |  ================                       |    |
                          |   |                                         |    |
                          |   |  Party on, dudes!                       |    |
                          |   |                                         |    |
                          |   |  C:\\> _                                 |    |
                          |   |                                         |    |
                          |   |                                         |    |
                          |   |_________________________________________|    |
                          |                                                  |
                           \\________________________________________________/
                                  \\___________________________________/
                               ___________________________________________
                            _-'    .-.-.-.-.-.-.-.-.-.-.-.-.-.-.-.-.  --- \`- _
                         _ - '.-.-. .---.-.-.-.-.-.-.-.-.-.-.-.-.-.-.--.  .-.-.\`-_
                      _ - '.-.-.-. .---.-.-.-.-.-.-.-.-.-.-.-.-.-.-.-\`__\`. .-.-.-.\`-_
                   _ - '.-.-.-.-. .-----.-.-.-.-.-.-.-.-.-.-.-.-.-.-.-----. .-.-.-.-.\`-_
                _ - '.-.-.-.-.-. .---.-. .-------------------------. .-.---. .---.-.-.-.\`-_
               : -------------------------------------------------------------------------:
               \`---._.-------------------------------------------------------------._.---'
`);
};

window.version = function () {
	(async function () {
		const content = await (await fetch('/version-info.md')).text();
		console.log(content);
	})();
};
