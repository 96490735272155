import $ from 'jquery';

var disableFormSubmitSplashScreen = false; // Use if a form submit thinks it is valid even after returning false

function showFormSubmitSplashScreen() {
	var image = document.createElement('img');
	image.src = '/Content/Images/fp-gif-preloader-10.gif';
	image.style.position = 'fixed';
	image.style.bottom = '-30px';
	image.style.right = '-50px';
	image.style.width = '300px';

	var text = document.createElement('div');
	text.style.position = 'fixed';
	text.style.bottom = '20px';
	text.style.right = '50px';
	text.innerHTML = 'Submitting...';

	var splash = document.createElement('div');
	splash.className = 'form-submit-splash';
	splash.style.position = 'fixed';
	splash.style.top = 0;
	splash.style.left = 0;
	splash.style.width = '100%';
	splash.style.height = '100vh';
	splash.style.opacity = 0.5;

	splash.appendChild(image);
	splash.appendChild(text);

	document.body.appendChild(splash);
}

function hideFormSubmitSplashScreen() {
	var elems = document.getElementsByClassName('form-submit-splash');
	for (var elem of elems) {
		elem.remove();
	}
}

$(document).ready(function () {
	$('form[action]:not(.no-splash)').submit(function () {
		if ((typeof $(this).valid !== 'function' || $(this).valid())
			&& !window.disableFormSubmitSplashScreen) {
			showFormSubmitSplashScreen();
		}
	});
});

window.showFormSubmitSplashScreen = showFormSubmitSplashScreen;
window.hideFormSubmitSplashScreen = hideFormSubmitSplashScreen;
window.disableFormSubmitSplashScreen = disableFormSubmitSplashScreen;
