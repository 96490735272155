function format_date(date) {
	var parsedDate = new Date(date);

	if (parsedDate) {
		var offsetHours = parsedDate.getTimezoneOffset() / 60;	// The number of hours offset from GMT
		var offSetMinutes = 60 * (offsetHours % 1);				// Convert the decimal remainder (if any) into minutes

		// Hours, minutes, seconds, offset hours, and offsetminutes, padded left with a 0 if needed
		var hmso = [parsedDate.getHours(), parsedDate.getMinutes(), parsedDate.getSeconds(), offsetHours, offSetMinutes];
		for (i = 0; i < hmso.length; i++)
			if (hmso[i].toString().length == 1)
				hmso[i] = '0' + hmso[i];

		// Format as MM/DD/YYYY HH:MM:SS (+|-)00:00
		return (parsedDate.getMonth() + 1) + '/' + parsedDate.getDate() + '/' + parsedDate.getFullYear() + ' '
			+ hmso[0] + ':' + hmso[1] + ':' + hmso[2] + ' '
			+ (offsetHours > 0 ? '-' : '+') + hmso[3] + ':' + hmso[4];
	}
}

function fp_date_from_utc(utc) {
	return new Date(Date.UTC(utc.getFullYear(), utc.getMonth(), utc.getDate(), utc.getHours(), utc.getMinutes(), utc.getSeconds(), utc.getMilliseconds()));
}

function fp_formatted_datetime(date) {
	return `${fp_formatted_date(date)} ${fp_formatted_time(date)}`;
}

function fp_formatted_date(date) {
	return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
}

function fp_formatted_time(date) {
	let hours = date.getHours();
	let minutes = date.getMinutes(); 
	let meridian = "AM";

	if (hours > 12) {
		hours -= 12;
		meridian = "PM";
	}
	else if (hours == 0) {
		hours = 12;
	}

	if (minutes < 10) {
		minutes = `0${minutes}`;
	}

	return `${hours}:${minutes} ${meridian}`;
}

// Exports
window.format_date = format_date;
window.fp_date_from_utc = fp_date_from_utc;
window.fp_formatted_datetime = fp_formatted_datetime;
window.fp_formatted_date = fp_formatted_date;
window.fp_formatted_time = fp_formatted_time;