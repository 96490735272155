/**
 * Used for fetching Future Plans endpoints that make use of the SuccessResponseModel/ExceptionResponseModel return types.
 * */

/**
 * Converts an object of key/values to a query string parameter of the form key1=value1&key2=value2.
 * 
 * @param {object} params - An object of key/values
 * @returns {string} The resulting query string parameter
 */
const objectToParams = function (params) {
	return Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
}

/**
 * Fetches and parses JSON from Future Plans endpoints that make use of the SuccessResponseModel/ExceptionResponseModel return types.
 *
 * Throws Errors under the following conditions:
 * 1. A non-200 response
 * 2. If result.Success is undefined or not true
 *
 * @param {string} url
 * @param {object} params - An object of key/values to pass to the URL as query string parameters
 * @returns {object} The deserialized result.Data
 */
const fetchJsonWithParams = async function (url, params) {
	const paramsString = objectToParams(params);
	return await fetchJson(`${url}?${paramsString}`);
};

/**
 * Fetches and parses JSON from Future Plans endpoints that make use of the SuccessResponseModel/ExceptionResponseModel return types.
 *
 * Throws Errors under the following conditions:
 * 1. A non-200 response
 * 2. If result.Success is undefined or not true
 *
 * @param {string} url
 * @returns {object} The deserialized result.Data
 */
const fetchJson = async function (url) {
	const response = await fetch(url);
	if (!response.ok) throw new Error(response.statusText);
	const result = await response.json();
	if (typeof result.Success === 'undefined') throw new Error('An unexpected error was encountered.');
	if (!result.Success) throw new Error(result.Errors.join("\n"));

	return result.Data;
};

/**
 * Serializes the provided payload, posts it, and parse the resultant JSON from Future Plans endpoints that make use of the
 * SuccessResponseModel/ExceptionResponseModel return types.
 *
 * Throws Errors under the following conditions:
 * 1. A non-200 response
 * 2. If result.Success is undefined or not true
 *
 * @param {string} url
 * @param {any} payload - The payload object to POST, which will be JSON serialized
 * @returns {object} The deserialized result.Data
 */
const postJson = async function (url, payload) {
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		body: JSON.stringify(payload)
	});
	if (!response.ok) throw new Error(response.statusText);
	const result = await response.json();
	if (typeof result.Success === 'undefined') throw new Error('An unexpected error was encountered.');
	if (!result.Success) throw new Error(result.Errors.join("\n"));

	return result.Data;
};

export {
	objectToParams,
	fetchJsonWithParams,
	fetchJson,
	postJson
};