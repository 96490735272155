import $ from 'jquery';
import * as toastr from 'toastr';

$(document).ready(function () {
	$('.application-feature-opt-toggle:checkbox').change(async function () {
		const id = $(this).data('id');
		const reviewMode = $(this).data('review-mode');
		const targetUserID = reviewMode ? $(this).data('target-user-id') : null;
		const redirect = $(this).data('redirect');
		const action = this.checked ? 'OptIn' : 'OptOut';

		if (reviewMode && targetUserID === null) {
			toastr.options.positionClass = 'toast-bottom-right';
			toastr.error(`Target user cannot be unspecified when in review mode.`);
			this.checked = !this.checked;
			return false;
		}

		const model = {
			ApplicationFeatureID: id,
			TargetUserID: targetUserID,
		};

		const response = await fetch(`/API/ApplicationFeatures/${action}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify(model),
		});

		if (!response.ok) {
			toastr.options.positionClass = 'toast-bottom-right';
			toastr.error(`Unable to toggle feature opt-in: ${response.statusText}`);
			this.checked = !this.checked;
			return false;
		}

		const result = await response.json();
		if (result.Success !== true) {
			toastr.options.positionClass = 'toast-bottom-right';
			toastr.error(`Unable to toggle feature opt-in: ${result.Errors.join(',')}`);
			this.checked = !this.checked;
			return false;
		}

		toastr.options.positionClass = 'toast-bottom-right';
		toastr.success(`Your selection has been updated!`);

		if (redirect) {
			location.href = redirect;
		}
		else {
			location.reload(true);
		}
	});
});