import $ from 'jquery';

/**
 * An internal function used for presenting modals.
 * 
 * @param {string} selector - A jQuery selector used to target an existing modal HTML document element
 * @param {string} title - The modal title
 * @param {string} body - The modal body
 */ 
function _show_modal_base(selector, title, body) {
	const $modal = $(selector);
	const $modalTitle = $modal.find('.modal-title');
	const $modalBody = $modal.find('.modal-body div');

	let $body = $(`<div>${body ? body : ''}</div>`);
	const $styles = $body.find('style');
	$styles.each((index, elem) => {
		const oldRules = elem.innerText;
		const newRules = oldRules.replace(/([^}{}]+)({[^}]*})/gm, ` ${selector} $1 $2`);
		elem.innerText = newRules;
	});

	$modalTitle.text(title);
	$modalBody.html($body.html());

	$modal.on('hidden.bs.modal', () => {
		$modalTitle.text('');
		$modalBody.html('');
	});

	return $modal;
}

/**
 * Presents a modal for displaying general notifications with no buttons.
 *
 * @param {string} title - The modal title 
 * @param {string} body - The modal body 
 */
function show_modal_notification(title, body) {
	const $modal = _show_modal_base('#notification-modal', title, body);
	$modal.modal('show');

	return $modal;
}

/**
 * Presents a modal with a submit and cancel button.
 * 
 * @param {string} title - The modal title
 * @param {string} content - The modal content
 * @param {string} submitText - The submit button text
 * @param {string} cancelText - The cancel button text
 * @param {func} onSubmit - An async callback function that handles when the submit button is clicked
 * @param {func} onCancel - An async callback function that handles when the cancel button is clicked
 */
function show_modal_form(title, content, submitText, cancelText, onSubmit, onCancel) {
	submitText ||= 'Submit';
	cancelText ||= 'Cancel';

	const $modal = _show_modal_base(
		'#form-modal',
		title,
		`<form onsubmit="return false;">
			${content}
		</form>`);

	$modal.find('.modal-footer').html(
		`<div class="input-wrapper" style="text-align: right;">
			<button type="button" class="button save-button">${submitText}</button>
			<button type="button" class="button cancel-button">${cancelText}</button>
		</div>`);

	$modal.find('.save-button').on('click', async function () {
		const $submitButton = $(this);
		$submitButton.text('Submitting...');

		$modal.find('.modal-footer button').each(function () {
			$(this).prop('disabled', true);
		});

		try {
			if (onSubmit) {
				await onSubmit($modal);
			}
		}
		finally {
			$submitButton.text(submitText);

			$modal.find('.modal-footer button').each(function () {
				$(this).prop('disabled', false);
			});
		}
	});

	$modal.find('.cancel-button').on('click', async function () {
		if (onCancel) {
			await onCancel($modal);
		}

		$modal.modal('hide');
	});

	$modal.modal('show');

	return $modal;
}

/**
 * Presents a modal for showing informational messages with with an OK button.
 * 
 * @param {string} message - The modal body message
 * @param {string} title - The modal title
 */
function show_modal_info(message, title) {
	const $modal = $('#info-modal');
	$modal.find('.modal-body div').html(message);
	$modal.find('.modal-title').text(title);
	$modal.modal('show');
	return $modal;
}

/**
 * Presents a modal for showing confirmation messages with Yes/No buttons.
 * 
 * @param {string} message - The modal body message
 * @param {string} title - The modal title
 * @param {func} func - A callback function that handles when the Yes button is clicked
 */
function show_modal_confirm(message, title, func) {
	const $modal = $('#confirm-modal');
	$modal.find('.modal-body div').html(message);
	$modal.find('.modal-title').text(title);
	$modal.modal('show');
	$('#confirmModalYes').off('click');
	$('#confirmModalYes').on('click', function () {
		if (func) func();
		$modal.modal('hide');
	});
	return $modal;
}

// TODO: Look for a way to roll these window exports up to entrypoints
window.show_modal_notification = show_modal_notification;
window.show_modal_form = show_modal_form;
window.show_modal_info = show_modal_info;
window.show_modal_confirm = show_modal_confirm;

export {
	show_modal_notification,
	show_modal_form,
	show_modal_info,
	show_modal_confirm,
};