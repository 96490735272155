import $ from 'jquery';
import '../../../vendor/clamp-js/clamp.js';

$(document).ready(function () {
	$('.panel-button-hover').mouseenter(function () {
		$(this).find('.panel-button-shine').addClass('animate__animated animate__pulse');
	}).mouseleave(function () {
		$(this).find('.panel-button-shine').removeClass('animate__animated animate__pulse');
	});

	$('.expandable-panel-header:not(.fixed)').click(function () {
		var targetPanel = $(this).next('.expandable-panel');
		var closed = !targetPanel.hasClass('closed');
		targetPanel.toggleClass('closed');
		$(this).find('i.fa').last().removeClass('fa-chevron-' + (closed ? 'up' : 'down')).addClass('fa-chevron-' + (closed ? 'down' : 'up'));
	});
});
